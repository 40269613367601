import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M1671 2290 c0 -206 31 -319 129 -468 96 -145 210 -232 387 -293 l98
-34 320 0 c296 1 326 2 397 22 229 64 374 185 482 400 52 104 66 175 65 328
-1 125 -1 128 -14 83 -17 -63 -67 -150 -124 -217 -93 -109 -226 -189 -391
-233 -81 -22 -102 -23 -410 -23 -304 1 -330 2 -405 23 -251 69 -441 237 -509
450 l-25 77 0 -115z m589 -535 c0 -10 -10 -15 -30 -15 -16 0 -30 -4 -30 -10 0
-5 9 -10 20 -10 11 0 20 -7 20 -15 0 -8 -9 -15 -19 -15 -14 0 -21 -8 -23 -27
-2 -17 -9 -28 -18 -28 -12 0 -16 15 -18 68 l-3 67 51 0 c38 0 50 -4 50 -15z
m50 -40 l0 -55 35 0 c25 0 35 -4 35 -16 0 -12 -10 -14 -47 -12 l-48 3 -3 68
c-2 55 0 67 13 67 12 0 15 -12 15 -55z m189 -3 c13 -31 25 -63 28 -69 3 -7 -2
-13 -11 -13 -9 0 -16 5 -16 10 0 6 -15 10 -34 10 -19 0 -38 -4 -41 -10 -13
-21 -27 -9 -20 18 12 50 44 112 57 112 7 0 24 -26 37 -58z m136 38 c0 -10 -8
-14 -27 -12 -38 5 -35 -11 5 -29 40 -18 43 -49 5 -66 -19 -10 -34 -10 -53 -3
-34 13 -23 31 15 23 23 -4 30 -2 30 11 0 9 -8 16 -18 16 -37 0 -55 43 -30 68
18 18 73 11 73 -8z m65 -5 c0 -21 5 -25 30 -25 25 0 30 4 30 25 0 16 6 25 15
25 13 0 15 -12 13 -67 -2 -42 -8 -68 -15 -71 -9 -2 -13 7 -13 27 0 28 -3 31
-30 31 -27 0 -30 -3 -30 -30 0 -20 -5 -30 -15 -30 -12 0 -15 14 -15 70 0 56 3
70 15 70 9 0 15 -9 15 -25z m159 -22 c8 -24 18 -43 22 -43 3 0 12 20 19 45 9
32 18 45 31 45 16 0 15 -6 -10 -67 -39 -96 -47 -95 -91 16 -18 47 -19 52 -4
49 10 -2 24 -22 33 -45z m221 32 c0 -8 -9 -15 -19 -15 -17 0 -20 -8 -23 -52
-4 -74 -28 -77 -28 -4 0 53 -1 56 -25 56 -16 0 -25 6 -25 15 0 12 13 15 60 15
47 0 60 -3 60 -15z m40 -29 c0 -46 19 -72 45 -62 11 4 15 20 15 56 0 38 4 50
15 50 12 0 15 -13 15 -59 0 -55 -2 -59 -29 -71 -23 -9 -34 -9 -53 1 -33 17
-38 28 -38 82 0 35 4 47 15 47 11 0 15 -12 15 -44z"/>
<path d="M2455 1707 c-8 -19 -2 -31 13 -26 5 2 6 13 2 24 -7 19 -8 19 -15 2z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
